<template>
    <div class="background bg-gray-900 mt-16 flex flex-col h-screen w-full justify-center items-center p-50"
    >
        <h1 class="text-4xl mb-20 text-center text-blue-200">{{ message }}</h1>

        <router-link class=" flex text-center text-white text-xl hover:text-green-300" tag="a" to="/login">Войти в
            личный кабинет<img
                class="ml-5" src="@/assets/img/icons/rightarr.svg" alt="Белтаможсервис"></router-link>
        <router-link class=" flex text-center text-white text-xl hover:text-green-300" tag="a" to="/">На главную <img
            class="ml-5" src="@/assets/img/icons/rightarr.svg" alt="Белтаможсервис"></router-link>
    </div>
</template>

<script>
import axios from "axios";

export default {
    meta:{
        title:'Подтверждение регистрации'
    },
    name: "ConfirmRegistration",
    data: () => ({
        status: false,
        message: ''
    }),
    mounted() {
        this.confirm()
    },
    methods: {
        confirm() {
            let id = this.$route.query.ID
            let code = this.$route.query.CONFIRM_CODE


            axios.post('https://declarant.by/rest/user/confirm', {id:id, code:code})
                .then(res => {
                    this.status = res.data.data
                    this.message = 'Ваш аккаунт подтвержден'
                    this.$notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                }).catch(err => {
                this.$notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
                this.message = 'Ошибка активации'
            })

        }
    }
}
</script>

<style scoped>

</style>